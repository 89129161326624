@import url("~leaflet/dist/leaflet.css");
@import url('~react-leaflet-markercluster/dist/styles.min.css');
@import url('~leaflet-geosearch/dist/geosearch.css');
@import url('~leaflet.zoomslider/src/L.Control.Zoomslider.css');


.leaflet-container {
    height: calc(100vh - 77px);
}

.leaflet-popup-content-wrapper {
    width: 176px;
    background: #222738;
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 8px;
    line-height: 120%;
    color: #E3E3E3;
}

.leaflet-container a.leaflet-popup-close-button {
    top: 9px
}

.leaflet-popup-content-wrapper h1 {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 10px;
    line-height: 100%;
    /* or 10px */

    display: flex;
    align-items: center;

    /* Text white */

    color: #E3E3E3;
    width: 90%;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    cursor: pointer;
}

.leaflet-popup-content-wrapper div div {
    display: flex;
}

.leaflet-popup-tip {
    background: #222738;
}

.marker_title {
    margin-top: 0 !important;
    margin-bottom: 8px !important;
    margin-right: 30px;
    width: 50px;
    display: flex;
    align-items: center;

}

.marker_info {
    margin-top: 0 !important;
    margin-bottom: 8px !important;
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 8px;
    line-height: 120%;
    color: #E3E3E3;
}

.marker_street {
    margin-top: 0 !important;
    margin-bottom: 8px !important;
}


.polPosition {
    position: relative;
}

.pol {
    position: absolute;
    z-index: 4001;
    left: -6px;
    top: 50%;
    cursor: pointer;
}

.pol img {
    height: 80px;
    width: 30px;
}

.leaflet-marker-pane {
    z-index: 650;
}


/*zoom*/
.leaflet-touch .leaflet-bar a {
    width: 20px;
    height: 20px;
    background-color: #3A57E8;
    background-size: 10px;
    background-position: 50% 50%;
    box-shadow: 0px 2px 4px rgba(58, 87, 232, 0.3);
    background-repeat: no-repeat;
    border-radius: 4px !important;
}

.leaflet-touch .leaflet-bar .leaflet-control-zoom-in {
    background-image: url("../../../../assets/icons/map-controls/Plus.svg");
}

.leaflet-touch .leaflet-bar .leaflet-control-zoom-out {
    margin-top: 8px;
    background-image: url("../../../../assets/icons/map-controls/Minus.svg");
}

.leaflet-bar a.leaflet-disabled {
    background-color: #618DFF;
}

.leaflet-bar a, .leaflet-bar a:hover {
    background-color: #618DFF;
}

.blur {
    filter: blur(2px);
}

/*tooltip*/
.tooltip_move {
    background: green;

}

.tooltip_stop {
    background: orange;
}

.tooltip_error {
    background: red;
}

.tooltip_no_connected {
    background: gray;
}

.leaflet-tooltip {
    border: none;
}

.tooltip_name {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 15px;
    color: white;
    border: none;
    border-radius: 4px;
}


.leaflet-tooltip-bottom:before {
    display: none;
}

.leaflet-popup{
    z-index: 999 !important;
}

.leaflet-control-attribution{
    display: none;
}



/*layer*/
.leaflet-control-layers-toggle {
    margin-right: 0;

    /* Blue button */
    box-shadow: 0px 2px 4px rgba(58, 87, 232, 0.3);
    border-radius: 4px;
}

.leaflet-control-layers-toggle {
    height: 20px !important;
    width: 20px !important;
}

.leaflet-touch .leaflet-control-layers, .leaflet-touch .leaflet-bar {
    border: none;
}

.leaflet-retina .leaflet-control-layers-toggle {
    background-color: #3A57E8 !important;
    /* Blue button */
    background-image: url("../../../../assets/icons/map-controls/layer.svg") !important;
    background-size: 15px !important;
    background-position: 50% 50% !important;
    box-shadow: 0px 2px 4px rgba(58, 87, 232, 0.3) !important;
    background-repeat: no-repeat !important;


}

.leaflet-touch .geosearch .leaflet-bar-part {
    background-image: url("../../../../assets/icons/mapSearch.svg");
    background-size: 12.5px;

}

.leaflet-control-geosearch a.leaflet-bar-part:before, .leaflet-control-geosearch a.leaflet-bar-part:after {
    opacity: 0;
}

.leaflet-geosearch-button.active .leaflet-bar-part {
    width: 20px !important;
}

.leaflet-control-geosearch form {
    left: 20px !important;
    padding: 0 !important;
}

.leaflet-control-geosearch form input {
    height: 19px !important;
    padding-right: 16px !important;
}

.leaflet-touch .leaflet-control-geosearch a.reset {
    line-height: 19px !important;
    right: -16px;
    background: transparent;
    box-shadow: none;
}

.leaflet-touch .leaflet-control-geosearch a.reset:hover {
    background: transparent;
}

/*zoomslider*/

.leaflet-control-zoomslider-in, .leaflet-control-zoomslider-out {
    display: none !important;
}

.leaflet-touch .leaflet-control-zoomslider {
    box-shadow: none;
    padding-left: 2px;
    border: 0px solid transparent !important;
}

.leaflet-control-zoomslider-knob {
    background: linear-gradient(45deg, #3A57E8 0%, #618DFF 100%);
    /* Blue button */
    box-shadow: 0px 2px 4px rgba(58, 87, 232, 0.3);
    border-radius: 2px;
}

.leaflet-touch .leaflet-control-zoomslider-knob {
    width: 20px !important;
    height: 10px !important;
    margin: 0 !important;
    border: none !important;

}

.leaflet-control-zoomslider-body {
    background: transparent !important;
    width: 20px !important;
    border: none !important;


}

.leaflet-control-zoomslider-wrap {
    background: rgba(0, 0, 0, 0.1) !important;
    box-shadow: inset 0px 1px 4px rgba(58, 87, 232, 0.5) !important;
    border-radius: 4px !important;
}


.btn_eye {
    width: 16px;
    height: 16px;

    background: red;

}



.eye_control {
    width: 20px;
    height: 20px;
    background-color: #3A57E8;
    /* Blue button */
    background-image: url("../../../../assets/icons/map-controls/Eye.svg");
    background-size: 15px;
    background-position: 50% 50%;
    box-shadow: 0px 2px 4px rgba(58, 87, 232, 0.3);
    background-repeat: no-repeat;
    position: relative;
    z-index: 500;
    top: 30px;
}

.eye_control:hover {
    background-color: #618DFF;
}

.leaflet-control-layers {
    margin-top: 40px !important;
}

.leaflet-tooltip:hover{
z-index: 9999999;

}


/*tooltip_menu*/
.tooltip_menu{
width: 200px;

}
.btn_tooltip_position{
    display: flex;
    justify-content: space-around;
}
.btn_tooltip_menu{

    font-weight: 600;
    font-size: 12px;
    color: #E3E3E3;
}
.btn_tooltip_position button:first-child{
    margin-right: 5px;
}
.tooltip_menu label{
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 19px;
    display: flex;
    align-items: center;

    color: #FFFFFF;
}
.ddd{

}