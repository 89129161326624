header {
    background: #222738;
    color: white;
}

.header_block {
    color: white;
    display: flex;
    justify-content: space-between;
}

hr {
    margin: 0;
}

.header_item img {
    margin: 8px;
}

.logo {
    margin-left: 21px;
}

.logo img {
    width: 36px;
}

.user {
    margin-right: 33px;
    font-family: 'DM Sans', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 21px;
    display: flex;
    align-items: center;
    text-align: right;
    /* Text white */
    color: #E3E3E3;
    cursor: pointer;
}

.user img {
    width: 24px;
    height: 24px;
}

nav {
    margin-left: 21px;
}

a {
    text-decoration: none;
    margin-right: 16px;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    font-family: 'Inter', sans-serif;
    /* identical to box height, or 133% */
    display: flex;
    align-items: center;

    /* Text white */

    color: #E3E3E3;
}

a img {
    width: 20px;
    margin-right: 5px;
}

a:hover {
    color: gray;
}

a.active {
    color: #5383FF;

    position: relative;
}

a.active:before {
    content: "";
    z-index: 1;
    position: absolute;
    width: 120%;
    top: 25px;
    left: -10%;
    border-bottom: 3px solid #5383FF;
}


.user {
    position: relative;
}

.profile_menu {
    display: none;
    position: absolute;
    top: 40px;
    z-index: 500;
    background: #222738;
    border: 1px solid #E3E3E3;
    box-sizing: border-box;
    border-radius: 4px;
    width: 114px;
    right: 5px;
}

.profile_menu ul {
    list-style: none;
    padding-left: 0;
    margin: 2px;

}

.profile_menu ul li {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 21px;
    display: flex;
    align-items: center;
    text-indent: 8px;
    color: #E3E3E3;
    cursor: pointer;
}

.profile_menu ul li:hover {
    opacity: 0.7;
}

.profile_menu ul li:last-child:hover {
    color: red;
    opacity: 1;
}

.user:hover .profile_menu {
    display: block;
}




/*model window*/

.params_select .row {
    margin-bottom: 8px;
    padding-right: 0;
}


.params_select h6 {
    font-weight: 500;
}

.params_select .form-select-sm {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 11px;
    line-height: 175%;
    /* or 21px */
    padding-left: 0;
    display: flex;
    align-items: center;
    text-indent: 8px;

    /* Text white */

    color: #E3E3E3;
    background-color: #222738;
    background-image: url("../../assets/icons/selectStr.svg");
    background-size: 12px;
}
.header_menu {
    width: 380px;

}

.modal-content {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    display: flex;
    align-items: center;
border-radius: 8px;
    /* Text white */

    color: #E3E3E3;
    background: #222738;
}

.container {
    padding: 0;
}

.modal-header {
    height: 30px;
    border-bottom: 1px solid #30384F;
    width: 100%;
}

.modal-title {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    /* Colors/Unactive */
    color: #8A92A6;
}


.btn-group {
    border: 1px #5383FF solid;
    border-radius: 4px;
}

.radio {
    padding: 0.25rem 1rem;
    border: none;
    box-shadow: none !important;
}

.model_button {
    display: flex;
    justify-content: flex-end;
}

.model_button button:first-child {
    margin-right: 10px;
}

.model_button button {
    height: 30px;
    width: 100px;
    padding: 0;
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 15px;
    text-align: center;
    color: #E3E3E3;
}

.btn-close {
    box-shadow: none !important;
    background-image: url("../../assets/icons/btn_close.svg");
    background-position-x: center;
    background-position-y: center;
    background-size: 20px;
    background-repeat-x: no-repeat;
    background-repeat-y: no-repeat;
    background-attachment: initial;
    background-origin: initial;
    background-clip: initial;
    background-color: transparent;
}

