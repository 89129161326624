
.toolbarBlock {
    position: relative;
    z-index: 9 !important;
    background: #151824;
    height: calc(100vh - 77px);
    overflow: hidden;
}

.tb_menu_block {
    display: flex;
    justify-content: space-between;
    margin-left: 1.5vw;
    margin-right: 1vw;

}

.car_track {
    width: 100%;
}

.tb_menu_btn {
    width: 60px;
    padding: 0;

}
.tb_menu_btn img, .tb_menu_icon img{
    cursor: pointer;
}

.tb_menu_icon {
    width: 180px;
    padding: 0;
    position: relative;
}

.tb_menu_block div img {
    margin-right: 4px;
}

.accordion_all_checkbox {
    display: flex;
    align-items: flex-end;
}

.input_block {
    display: flex;
    justify-content: center;
}

.input-group:not(.has-validation) > .dropdown-toggle:nth-last-child(n+3), .input-group:not(.has-validation) > :not(:last-child):not(.dropdown-toggle):not(.dropdown-menu) {
    border-radius: 0.25rem;

}

.input-group {
    width: 100%;
}

.input-group input {
    background: #222738;
    color: gray;
    height: 30px;
    border: 1px #8A92A6 solid;
    padding-right: 0;
}

.input-group input:focus {
    background: #222738;
    color: gray;
}
.input_btn_search{
    padding: 0;
}

.input_btn_search img{
    position: absolute;
    padding: 0;
    left: 10px;
    bottom: 3.5px;
}


.input_btn_search {
    height: 30px;
    background: transparent;
    border: none;
    right: 40px;
    bottom: 2px;
    z-index: 3 !important;
    font-size: 15px;
    box-shadow: none !important;

}



.form-check-input {
    margin-left: 5px;
    border: 1px solid #5383FF;
    background-color: transparent;
    margin-top: 2px;

}

.form-check-input[type=checkbox] {
    border-radius: 2px;
}

.car_checkbox .form-check-input {
    margin-left: 0;

}


.scroll {
    position: relative;
    height: 70vh;
    overflow-y: scroll;
    overflow-x: visible;
    border-right: 2px transparent solid;
    padding-right: 4px;
}

.scroll::-webkit-scrollbar, .group_space::-webkit-scrollbar {
    width: 6px;
}

.scroll::-webkit-scrollbar-track, .group_space::-webkit-scrollbar-track {
    /*background: #222738;*/
    border-radius: 0 0 8px 8px;

}

.scroll::-webkit-scrollbar-thumb, .group_space::-webkit-scrollbar-thumb {
    background: #8A92A6;
    border-radius: 4px;

}


.accordion {
    margin-bottom: 10px;
}

.card-header {
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.25);
    border-radius: 4px !important;
    background: #252D47;
    color: #E3E3E3;
    height: 35px;
    padding: 0;
}

.accordion_checkbox {
    display: flex;
    align-items: center;
}

.accordion_title {
    margin-top: 2px;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
}

.accordion_title_image {
    margin: auto 5px auto 5px;
    width: 10px;
    height: 18px;
    transform: rotate(90deg);
}

.accordion_title_image_open {
    margin: auto 5px auto 5px;
    width: 10px;
    height: 18px;
    transform: rotate(-90deg);

}

.card-body {
    padding: 0;

    background: #151824;

}

.toolbar_track_list {
    padding: 0;
    margin: 0;
    list-style: none;
}

.toolbar_track_list li {
    margin: 5px 0;
    background: #222738;
    border-radius: 4px;
    position: relative;
}

.accordion_body_position {
    display: flex;
    padding-bottom: 8px;
}

.card {
    background-color: transparent;
    border: none;
}

.model_car {
    margin: 0 5px;
    display: flex;
    align-items: center;

}

.car_info {
    display: flex;
}

.car_info_block {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    width: 100%;
    margin-top: 8px;
}

.car_icon {
    display: flex;
    flex-wrap: nowrap;
    position: relative;
    cursor: pointer;
}

.satellites {
    height: 16px;
    width: 16px;
    margin-right: 5px;
    border: 1px #1AA053 solid;
    border-radius: 50%;
    font-family: 'Roboto', sans-serif;
    font-size: 8px;
    line-height: 9px;
    color: #E3E3E3;
    text-align: center;
    padding-top: 2.3px;
}


.car_icon img {
    height: 16px;
    margin-right: 5px;
}

.car_number div h6 {
    margin: 0;
    padding: 0;
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 100%;
    /* or 12px */
    display: flex;
    align-items: center;
    /* Colors/Blue */
    color: #5383FF;
    cursor: pointer;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;

}

.car_number {
    flex-direction: column;
    margin: 0 5px;
}

.car_number div {
    display: flex;
    align-items: baseline;
    margin-top: -1px;

}

.indicator_stop {
    width: 8px;
    min-width: 8px;
    height: 8px;
    margin-right: 3px;
    background: #C03221;
    border-radius: 2px;
}

.indicator_move {
    width: 8px;
    min-width: 8px;
    height: 8px;
    margin-right: 3px;
    background: #1AA053;
    border-radius: 2px;
}

.car_speed p {
    margin: 0;
    padding: 0;
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 8px;
    line-height: 120%;
    /* or 10px */
    display: flex;
    align-items: center;
    /* Text white */
    color: #E3E3E3;
}

.car_time {
    display: flex;
    justify-content: flex-end;
    margin: 4px 8px 0 0;
}


.car_time p {
    margin: 0;
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 8px;
    line-height: 120%;
    /* or 10px */
    /* Text white */
    color: #E3E3E3;
}

.car_location p {
    margin: 0;
    padding: 0;
    width: 90%;
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    line-height: 100%;
    /* or 10px */
    display: flex;
    align-items: center;
    /* Text white */
    color: #E3E3E3;
}

/*menu*/


.extra_menu {
    background: #222738;
    border-radius: 4px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

}

.extra_menu_str {
    margin-top: 5px;
    transform: rotate(180deg);
    margin-right: 5px;
    height: 16px;
}

.extra_menu ul {
    list-style: none;
    margin: 0 0 8px 8px;
    padding-left: 0;
    opacity: 0;
}

.extra_menu .active_ul_menu {
    opacity: 1;
    transition: opacity 2s ease-out;
    margin-bottom: 0;
}

.extra_menu ul li {
    font-family: 'Inter', sans-serif;
    font-weight: 500;
    font-size: 12px;
    line-height: 100%;
    color: #8A92A6;
    margin: 8px 0px;
    cursor: pointer;
    display: flex;
    align-items: center;
}

.extra_menu .active_ul_menu li:nth-child(3){
    color: #5383FF;
}
.extra_menu .active_ul_menu li:hover{
    opacity: 0.5;
}

.activeLi {
    color: white;
}

.extra_menu ul li img {
    margin-right: 6px;
}

/******group menu*****/
.change_group_menu{
    width: 400px;
}
.group_menu_body{
    width: 350px !important;
}

.btn_create_group {
    width: 140px;
    height: 32px;
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding: 0;
    /* Text white */
    color: #E3E3E3;
}

.group_menu_body .row {
    margin-bottom: 8px;
}

.btn_add_to_group {
    width: 140px;
    height: 32px;
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding: 0;
    color: #E3E3E3;
}

.position_add_to_group {
    display: flex;
    justify-content: flex-end;
}

.group_space {
    max-height: 300px;
    overflow: auto;
}

.card-body {
    background: transparent;
}

.menu_track_list {
    padding: 0;
    margin: 0;
    list-style: none;
    background: transparent;
}

.menu_track_list li {
    height: 32px;
    display: flex;
    background: #252D47;
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
    border-radius: 4px;
    margin-top: 6px;
    align-items: center;

}

.menu_track_checkbox {
    margin-right: 6px;
}

.menu_track_name {
    width: 82%;
}

.menu_track_name h6 {
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    margin: 0;
    overflow: hidden;
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    color: #E3E3E3;
}
.menu_accordion_title{
    margin-left: 10px;
    margin-top: auto;
    margin-bottom: auto;
    cursor: pointer;
    display: flex;
}
.menu_group_name h5{
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    display: flex;
    align-items: center;

    /* Text white */

    color: #E3E3E3;
    margin-bottom: 0;
}
.menu_group_name h6{
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    line-height: 12px;
    display: flex;
    align-items: center;

    /* Buttons/Colors/Colors/Unactive */

    color: #8A92A6;
    margin-bottom: 0;
}


.menu_accordion_title img{
    height: 15px;
    width: 15px;
}
.menu_title_btn{
    display: flex;
    align-items: center;

}
.menu_title_btn img{
    width: 16px;
    height: 16px;
margin-left: 10px;
    cursor: pointer;
}

/*edit group menu*/
.edit_menu_search_input{
    background: #151824 !important;
}

/*add_group_menu*/
.add_group_menu{
    width: 320px;
}
.add_group_menu_body {
width: 300px !important;

}

.btn_add_to_group_group_menu{
    width: 100%;
}
.input_name_group_menu{
margin-bottom: 20px;
}
.add_group_menu_footer container{
    width: 300px!important;
}
.add_group_menu_footer{
    display: flex;
}
.add_group_menu_footer_position{
    display: flex;
    justify-content: center;
}
.add_group_menu_footer_position button{
    width:200px;
}

.add_group_menu_footer_position button:first-child{
    margin-right: 10px;
}
.modal-footer {
    padding-left: 0;
    padding-right: 0;
}