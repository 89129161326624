.table_menu{
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #252D47;
    mix-blend-mode: normal;
    border-radius: 4px 4px 0px 0px;
    height: 50px;
}
.close_table{
    display: flex;
}
.close_table img{
    margin: 12px 16px;
}
.close_table h2{
    font-family: Inter;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 100%;
    display: flex;
    align-items: center;
    text-transform: uppercase;
    color: #E3E3E3;
    margin-top:7px;
}
.table_item{
    margin-right:10px;
}
.table_item img{
    margin: 0 5px;
}

th{
    color: #8A92A6;
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 100%;
    height: 70px;
}

.th_align{
    padding-top: 10px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.th_align img, td img{
    cursor: pointer;
}

tr{
    color: #E3E3E3;
    height: 40px;
}
.table_margin{
    margin:20px 20px 0 20px !important;
    height: calc(100vh - 97px);
}
.table {
    --bs-table-hover-color: white;
    --bs-table-hover-bg: grey;
    margin-bottom: 0;

}
.table-responsive{
    overflow: auto;
    height: 75vh;
}
.table-responsive::-webkit-scrollbar{
    height: 8px;
    width: 8px;
}
.table-responsive::-webkit-scrollbar-track{
    background: #222738;
    border-radius: 0 0 0 4px;
}
.table-responsive::-webkit-scrollbar-thumb {
    background: #8A92A6;
    border-radius: 4px;
}

.table-responsive::-webkit-scrollbar-corner {
    border-radius: 0 0 4px;
    background: #222738;
}
.table>:not(caption)>*>*{
    border: none;
}
tr:nth-child(2n){
    background: #222738;
}
tr{
    background: #252D47;
}

thead tr{
    background: #151824;
}

.title_name{
    width: 260px;

}
.title_name .table_input{
    width: 150px;
}
.title_address{
    width: 260px;
}
.title_company{
    width: 160px;
}
.title_subdivision{
    width: 120px;
}
.title_driver{
    width: 130px;
}
.title_drp{
    width: 85px;
}
.title_transport_group{
    font-size: 12px;
    width: 130px;
    padding-top: 0;
}
.title_model{
    width: 155px;
}
.title_track_time{
    width: 190px;
}
.title_speed{
    font-size: 12px;
    width: 150px;
    padding-top: 0;
}
.title_hired{
    width: 120px;
}
.table_body_address{
    font-size: 12px;
}

.table_satellites{
    height: 16px;
    width: 16px;
   display: block;
    text-align: center;
    margin-top: 7px;
    padding-top: 3px;
    border: 1px green solid;
    border-radius: 50%;
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-size: 9px;
    line-height: 9px;
    /* Text white */
    color: #E3E3E3;
}

.table_input{
    height: 20px;
    color: #8A92A6;
    background: transparent;
    font-size: 15px;
    border: 1px solid #8A92A6;
    box-sizing: border-box;
    box-shadow: inset 0px 2px 4px #222738;
    border-radius: 4px;
    margin-top: 10px;
    vertical-align: bottom;
}


/*drop-menu*/
.gear {
    transform: rotate(60deg);
    transition: 0.5s;
    position: relative;
}
.main_menu {
    position: absolute;
    top:97px;
    right: 80px;
    z-index: 2;
    width: 220px;
    background: #222738;
    border-radius: 4px;
}

.active_main_menu {
    visibility: visible;
}

.hidden_main_menu {
    visibility: hidden;
}

.main_menu ul {
    list-style: none;
    margin: 8px;
    padding-left: 0;
    opacity: 0;
}
.main_menu .active_ul_menu{
    opacity: 1;
    transition: opacity 2s ease-out;
}
.main_menu ul li{
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 100%;
    color: #8A92A6;
    margin: 8px 0px;
    cursor: pointer;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
}
.active_main_menu .active_li{
    color: white;
}
.active_main_menu .active_li:hover{
    color: #5383FF;
}
.main_menu ul li img{
    margin-right: 6px;
}