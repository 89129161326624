.loginForm{
    width: 432px;
    height: 404px;
    background: rgba(49, 63, 109, 0.3);
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.logo_item{
    margin-top: 50px;
    margin-bottom: 20px;
}
.loginForm form{
    display: flex;
    flex-direction: column;
    color: white;
}
.bg{
    background-image: url("../../assets/login/login-bg.png");
    background-repeat: no-repeat;
    background-size: cover;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}
.login input, .login select{
    width: 292px;
    height: 44px;
    color: white;
    background: #8A92A6;
    opacity: 0.5;
    margin-bottom: 16px;
    border: 1px solid #63656F;
    box-shadow: inset 0px 1px 2px rgba(0, 0, 0, 0.25);
    border-radius: 4px;
    font-family: 'Inter', sans-serif;
    font-style: italic;
    font-weight: normal;
    font-size: 24px;
    line-height: 29px;
    text-indent: 8px;
    color: #E3E3E3;
}

.login input::placeholder, .login select {
    color: #E3E3E3;
}

.remember{
  display: flex;
    align-items: center;
    margin-bottom: 16px;
}
.remember input{
    width: 24px;
    height: 24px;
    margin-bottom: 0;
    margin-right: 10px;
    background: transparent;
    border: 1px #5383FF solid;
    opacity: 1;
}
.remember label{
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    text-indent: 8px;
    color: #E3E3E3;
}
.loginForm .btn{
    width: 120px;

    margin: auto;
}
